import React from "react";
import { Link } from "react-router-dom";
import { DiJavascript1 } from "react-icons/di";
import { FiArrowUpRight } from "react-icons/fi";
import { RiQuestionLine, RiFlutterFill } from "react-icons/ri";
import { FaReact, FaJava, FaPython, FaAngular } from "react-icons/fa";
import {
  client1,
  client2,
  client3,
  client4,
  trophy,
  services1,
  services2,
  services3,
  services4,
} from "../../assets/img";

import MetaData from "../../MetaData";
import ContactSection from "components/ContactUs/ContactSection";
import DefaultNavbar from "components/DefaultNavbar";

import "./services.css";

const ServiceLayout = ({
  title,
  description,
  heroSvg,
  children,
  uqeAboutus,
}) => {
  return (
    <>
      <MetaData title={title} content={description} keyword={title} />
      <DefaultNavbar />
      <section id="hero" className="hero d-flex align-items-center custom_hero">
        <div className="container m-auto mt-20 p-10  ">
          <div className="row custom_row herosection">
            <div className="herosection_div custom_child_div1 d-flex flex-column justify-content-center hero-container">
              <h1 data-aos="fade-up">
                {title}
                <br /> Development
              </h1>
              <h2 data-aos="fade-up" data-aos-delay="400">
                {description}
              </h2>
              <div data-aos="fade-up" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="#!"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Start your Project</span>
                    <FiArrowUpRight />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="herosection_div hero-img custom_child_div2"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              {heroSvg}
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="clients" className="clients">
          <div className="container m-auto">
            <div className="row custom_row client_custom_parent">
              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src={client1}
                  className="img-fluid"
                  alt={`${client1}, one of the best sellers collaborated with osperb innovertion malappuram`}
                  data-aos="zoom-in"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src={client2}
                  className="img-fluid"
                  alt={`${client2}, one of the best sellers collaborated with osperb innovertion malappuram`}
                  data-aos="zoom-in"
                  data-aos-delay="100"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src={client3}
                  className="img-fluid"
                  alt={`${client3}, one of the best sellers collaborated with osperb innovertion malappuram`}
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  style={{ transform: "scale(1.5)" }}
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src={client4}
                  className="img-fluid"
                  alt={`${client4}, one of the best sellers collaborated with osperb innovertion malappuram`}
                  data-aos="zoom-in"
                  data-aos-delay="300"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src={client1}
                  className="img-fluid"
                  alt={`${client1}, one of the best sellers collaborated with osperb innovertion malappuram`}
                  data-aos="zoom-in"
                  data-aos-delay="400"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6">
                <img
                  src={client2}
                  className="img-fluid"
                  alt={`${client2}, one of the best sellers collaborated with osperb innovertion malappuram`}
                  data-aos="zoom-in"
                  data-aos-delay="500"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="about pt-20 pb-20">
          <div className="container m-auto">
            <div className="section-title" data-aos="fade-up">
              <h2 className="hoverBlue">About Us</h2>
            </div>

            <div className="row custom_row content">
              <div
                className="col-lg-6 plr-1 about-w-full"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                {uqeAboutus}
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 plr-1 about-w-full"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
                <Link to="#!" className="btn-learn-more ">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="sectionProcess pt-20 pb-20 bg-black">
          <div className="container m-auto plr-1">
            <div className="processIcon">
              <h2 className="hoverBlue text-white">Our Process</h2>
              <div className="opicon">
                <div className="opIconItem">
                  <div className="circleText">1</div>
                  <div className="opext">Business Requirements</div>
                </div>
                <div className="opIconItem">
                  <div className="circleText">2</div>
                  <div className="opext">Development</div>
                </div>
                <div className="opIconItem">
                  <div className="circleText">3</div>
                  <div className="opext">QA Processes</div>
                </div>
                <div className="opIconItem">
                  <div className="circleText">4</div>
                  <div className="opext">Deployment to App Store</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container m-auto plr-1">
            <div className="ourSkill ">
              <div className="ourSkillRight">
                <h4 className="mb-3 font-bold text-2xl text-white">
                  Our Skills
                </h4>
                <ul
                  typeof="circle"
                  style={{
                    liststyle: "disc",
                    color: "white",
                    lineHeight: "35px",
                  }}
                  className="skill_li"
                >
                  <li>Creative Direction</li>
                  <li>Product Development Strategy</li>
                  <li>Mobile App marketplace strategy</li>
                  <li>Mobile App Full Stack Development</li>
                  <li>Application Solutions</li>
                </ul>
              </div>
              <div className="ourSkillLeft text-white">
                <h4>Tools We Use</h4>
                <span>Mobile Framework</span>
                <div className="iconGrid">
                  <FaReact size={"2.5rem"} />

                  <RiFlutterFill size={"2.5rem"} />
                </div>
                <span>Other Tools</span>
                <div className="iconGrid">
                  <DiJavascript1 size={"2.5rem"} />

                  <FaJava size={"2.5rem"} />

                  <FaPython size={"2.5rem"} />

                  <FaReact size={"2.5rem"} />

                  <FaAngular size={"2.5rem"} />
                </div>
                {/* <Link to="/technologies" className="btn-get-started bg-black">
                  <span>More Technologies</span>
                  <FiArrowUpRight />
                </Link> */}
              </div>
            </div>
          </div>
        </section>

        <section id="counts" className="counts pt-20 pb-20">
          <div className="container m-auto">
            <div className="row custom_row mt-20">
              <div
                className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start"
                data-aos="fade-right"
                data-aos-delay="150"
              >
                <img
                  src={trophy}
                  alt="At osperb, our team has equipped with a visionary agenda on making human life and activities easier"
                  className="img-fluid"
                />
              </div>

              <div
                className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0"
                data-aos="fade-left"
                data-aos-delay="300"
              >
                <div className="content d-flex flex-column justify-content-center">
                  <div className="row custom_row">
                    <div className="col-md-6 col-md-12 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-emoji-smile"></i>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="65"
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <p>
                          <strong>Happy Clients</strong> it is our pleasure to
                          obtain something from the very architect.
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 col-md-12 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-journal-richtext"></i>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="85"
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <p>
                          <strong>Projects</strong> to obtain and when because
                          he rejects the whole of those who praise him and
                          because he despises them
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 col-md-12 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-clock"></i>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="18"
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <p>
                          <strong>Years of experience</strong> or let him seek
                          for some sort of advantage
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6 col-md-12 d-md-flex align-items-md-stretch">
                      <div className="count-box">
                        <i className="bi bi-award"></i>
                        <span
                          data-purecounter-start="0"
                          data-purecounter-end="15"
                          data-purecounter-duration="1"
                          className="purecounter"
                        ></span>
                        <p>
                          <strong>Awards</strong> The pain of the hardships
                          sometimes accuses him and no one else pad der
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services">
          <div className="container m-auto">
            <div className="section-title " data-aos="fade-up">
              <h2 className="hoverBlue">Services</h2>
              <p>It takes great pains to gain from something</p>
            </div>
            <div className="row custom_row ">
              <div className="col-md-6 col-md-12 col-lg-3 md-pb-2 align-items-stretch mb-3 prl-1 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <Link to="#!">Software Development</Link>
                  </h4>
                  <p className="description">
                    To soothe and corrupt pleasures which they will welcome
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-md-12 col-lg-3 md-pb-2 align-items-stretch mb-3 prl-1 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <h4 className="title">
                    <Link to="#!">Mobile App Development</Link>
                  </h4>
                  <p className="description">The pain will in pleasure</p>
                </div>
              </div>

              <div className="col-md-6 col-md-12 col-lg-3 md-pb-2 align-items-stretch mb-3 prl-1 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="icon">
                    <i className="bx bx-tachometer"></i>
                  </div>
                  <h4 className="title">
                    <Link to="#!">Website Development</Link>
                  </h4>
                  <p className="description">
                    They are the exceptions who crave blacks, they do not see,
                    they are the ones who blame the services
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-md-12 col-lg-3 md-pb-2 align-items-stretch mb-3 prl-1 mb-lg-0">
                <div
                  className="icon-box"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="icon">
                    <i className="bx bx-world"></i>
                  </div>
                  <h4 className="title">
                    <Link to="#!">ERP Development</Link>
                  </h4>
                  <p className="description">
                    But indeed we both accuse and deem those most worthy of
                    flattery by the hatred of the just
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="more-services" className="more-services pb-20">
          <div className="container m-auto p-10 more_services">
            <h4 className="title mb-4 font-bold text-2xl">More services</h4>
            <div className="row custom_row">
              <div className="col-md-6 col-md-12 d-flex align-items-stretch p-10">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${services1})`,
                  }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="card-body" style={{ width: "60%" }}>
                    <h5 className="card-title">
                      <Link to="#!">Social media marketing</Link>
                    </h5>
                    <p className="card-text">
                      Lorem ipsum dolor sit amet, consectetur elit, sed do
                      eiusmod tempor ut labore et dolore magna aliqua.
                    </p>
                    <div className="read-more">
                      <Link to="#!">
                        <i className="bi bi-arrow-right"></i> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-md-12 d-flex align-items-stretch mt-md-0 p-10">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${services2})`,
                  }}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="card-body" style={{ width: "60%" }}>
                    <h5 className="card-title">
                      <Link to="#!">Designing</Link>
                    </h5>
                    <p className="card-text">
                      But in order to understand whence all this error was born,
                      the pleasure and pain of those praising the whole thing.
                    </p>
                    <div className="read-more">
                      <Link to="#!">
                        <i className="bi bi-arrow-right"></i> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-md-12 d-flex align-items-stretch p-10">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${services3})`,
                  }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="card-body" style={{ width: "60%" }}>
                    <h5 className="card-title">
                      <Link to="#!">Nive Lodo</Link>
                    </h5>
                    <p className="card-text">
                      For no one shuns pleasure itself, because it is pleasure,
                      or hates it, but because it is of great sorrows.
                    </p>
                    <div className="read-more">
                      <Link to="#!">
                        <i className="bi bi-arrow-right"></i> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-md-12 d-flex align-items-stretch p-10">
                <div
                  className="card"
                  style={{
                    backgroundImage: `url(${services4})`,
                  }}
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="card-body" style={{ width: "60%" }}>
                    <h5 className="card-title">
                      <Link to="#!">Pale Treda</Link>
                    </h5>
                    <p className="card-text">
                      He is ours, but we also encounter pain. Great addition
                      anyone cheering for the pleasure.
                    </p>
                    <div className="read-more">
                      <Link to="#!">
                        <i className="bi bi-arrow-right"></i> Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="faq pb-20">
          <div className="container m-auto p-20">
            <div className="section-title" data-aos="fade-up">
              <h2 className="hoverBlue">Frequently Asked Questions</h2>
            </div>

            <div
              className="row custom_row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="col-lg-5 prl-1">
                <RiQuestionLine />
                <h4>
                  Was it not the main reason for it to push the bed at least?
                </h4>
              </div>
              <div className="col-lg-7 prl-1">
                <p>
                  The price of the product itself Time for the arrows to push
                  this weekend the lakes will not be cured during pregnancy. The
                  venenatis of the bed is a great ecological urn, the pain is
                  not pure.
                </p>
              </div>
            </div>

            <div
              className="row custom_row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="col-lg-5 prl-1">
                <RiQuestionLine />

                <h4>
                  Check out the various diseases of the throat that are now
                  driving away?
                </h4>
              </div>
              <div className="col-lg-7 prl-1">
                <p>
                  Pain is the main cause of the disease. That's sometimes it
                  wants to pay for it until it's vengeful. The kids are the
                  hardest to bear.
                </p>
              </div>
            </div>

            <div
              className="row custom_row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="col-lg-5 prl-1">
                <RiQuestionLine />

                <h4>
                  Is there pain that can be the main cause of the disease?
                </h4>
              </div>
              <div className="col-lg-7 prl-1">
                <p>
                  Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                  sagittis orci. Faucibus pulvinar elementum integer enim. Sem
                  nulla pharetra diam sit amet nisl suscipit. Rutrum tellus
                  pellentesque eu tincidunt. Lectus urna duis convallis
                  convallis tellus.
                </p>
              </div>
            </div>

            <div
              className="row custom_row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="col-lg-5 prl-1">
                <RiQuestionLine />

                <h4>
                  Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla?
                </h4>
              </div>
              <div className="col-lg-7 prl-1">
                <p>
                  Aperiam itaque sit optio et deleniti eos nihil quidem cumque.
                  Voluptas dolorum accusantium sunt sit enim. Provident
                  consequuntur quam aut reiciendis qui rerum dolorem sit odio.
                  Repellat assumenda soluta sunt pariatur error doloribus fuga.
                </p>
              </div>
            </div>

            <div
              className="row custom_row faq-item d-flex align-items-stretch"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="col-lg-5 prl-1">
                <RiQuestionLine />

                <h4>
                  Tempus quam pellentesque nec nam aliquam sem et tortor
                  consequat?
                </h4>
              </div>
              <div className="col-lg-7 prl-1">
                <p>
                  Molestie a iaculis at erat pellentesque adipiscing commodo.
                  Dignissim suspendisse in est ante in. Nunc vel risus commodo
                  viverra maecenas accumsan. Sit amet nisl suscipit adipiscing
                  bibendum est. Purus gravida quis blandit turpis cursus in
                </p>
              </div>
            </div>
          </div>
        </section>

        <ContactSection />
      </main>
    </>
  );
};

export default ServiceLayout;
