import React from "react";
import MetaData from "../MetaData";

import "../assets/styles/singleWork.css";

import DefaultNavbar from "../components/DefaultNavbar";
import StickySection from "../components/singleWork/StickySection";
import ContactSection from "../components/ContactUs/ContactSection";

function SingleWorks() {
  return (
    <>
      <MetaData title={"Home"} name={"description"} content={""} />
      <DefaultNavbar />
      <main className="h-full pt-20">
        <div className="relative mb-40 block pt-10">
          <div className="container max-w-7xl mx-auto px-4">
            <StickySection />
          </div>
        </div>
      </main>
      <ContactSection />
    </>
  );
}

export default SingleWorks;
