import React from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title, content, keyword, description }) => {
  return (
    <Helmet>
      <title>{`Osperb | ${title}`}</title>
      <link rel="canonical" href="/" />
      <meta property="og:title" content={content} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://osperb.com" />
      <meta
        property="og:image"
        content={
          "public/osperb-software-development-team-in-malappuram-kerala.jpg"
        }
      />
      <meta name="keywords" content={keyword}></meta>
      <meta
        name="google-site-verification"
        content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34="
      />
    </Helmet>
  );
};

export default MetaData;
