import React, { useState } from "react";
import Paragraph from "@material-tailwind/react/Paragraph";
import { CardImage } from "@material-tailwind/react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { IoLocationSharp } from "react-icons/io5";
import { RiBuilding3Line } from "react-icons/ri";

import { WorkData } from "../../assets/config";

import CallBackModal from "../common/CallBackModal";

function StickySection() {
  const params = useParams();

  const singleWorkData = WorkData.find((i) => i.id === params.id);
  const [showModal, setShowModal] = useState(false);
  const [clickedImg, setClickedImg] = useState("");

  const handleModal = (img) => {
    setShowModal(true);
    setClickedImg(img);
  };

  return (
    <>
      <CallBackModal
        isGallery
        image={clickedImg}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <div className="bg-white md-px-auto p-sm-0">
        <div className="container max-w-7xl mx-auto px-1">
          <div className="flex items-start h-auto justify-between relative sm:flex-invert">
            <div className="w-full md:w-6/12 px-4 mx-auto flex flex-col justify-center mt-24 lg:mt-0 ">
              {singleWorkData?.images?.map((image, key) => (
                <CardImage
                  alt={`${image} - osperb innovations`}
                  src={image}
                  key={key}
                  className="mt-0 mb-3"
                  onClick={() => handleModal(image)}
                />
              ))}
            </div>
            <div className="w-full sticky sm:initial top-20 md:w-6/12 mx-auto px-4 h-auto">
              <div className="">
                <h1
                  color="gray"
                  className="font-mont text-2xl leading-normal mt-0 mb-4"
                >
                  {singleWorkData?.category}
                </h1>
                <p className="flex items-center text-sm">
                  <RiBuilding3Line className="mr-2" />
                  {singleWorkData?.clientName}
                </p>

                <p className="flex items-center text-sm">
                  <IoLocationSharp className="mr-2" />
                  {singleWorkData?.location}
                </p>
                <Paragraph color="blueGray">
                  {singleWorkData?.footerContent}
                </Paragraph>
                {singleWorkData?.specsContent && (
                  <>
                    <h6 className="pt-2 mt-10">Features:</h6>
                    <ol>
                      {singleWorkData?.specsContent?.map((spec, key) => (
                        <li>
                          <Paragraph>{spec}</Paragraph>
                        </li>
                      ))}
                    </ol>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StickySection;
