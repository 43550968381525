import LeadText from "@material-tailwind/react/LeadText";

export default function Title({ heading, children }) {
  return (
    <div className="flex flex-wrap justify-center text-center mb-20">
      <div className="w-full lg:w-6/12 px-4">
        {/* <H3 color="gray">{heading}</H3> */}
        <p className=" text-5xl fancy-font font-bold leading-normal mt-0 mb-2 sm:text-4xl">
          {heading}
        </p>
        <LeadText color="blueGray">{children}</LeadText>
      </div>
    </div>
  );
}
