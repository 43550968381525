import {
  Ecolums_h,
  Indtech_h,
  loyal_h,
  Suppriya_h,
  Loha_h,
  Layamr_h,
  IndtechApp_h,
  LohaApp_h,
  Ipasbook_h,
  IpasOman_h,
  Ecolums_web1,
  Loha_web2,
  Layamr_web2,
  Loyal_web1,
  Supriya_web1,
  Loha_fms1,
  Loha_fms2,
  Indtech_fms1,
  Indtech_fms2,
  Indtech_fms3,
  IpasOman_web1,
  Ipas_web1,
  Ipas_web2,
  Layamr_web1,
  Loha_web1,
  Zeston_app1,
  Zeston_web_dashboard1,
  Zeston_web_dashboard2,
  Zeston_web_dashboard3,
  Zeston_web_dashboard4,
  Zeston_web1,
  zeston_app,
  Layamr_web3,
  Layamr_web4,
  Loha_fms_h,
  Loha_fms3,
  Deals2Home_h,
  Deals2Home,
  Deals2Home1,
  Deals2Home2,
} from "./img";

export const colors = {
  navColor: "#333",
  white: "#fff",
  black: "#000",
  navBrandColor: "#1881bb",
  osperb: "#1881bb",
};

// 1881bb
//first 3 object for homepage
//use "All", "Web","Mobile", "ERP" for fiilter option you can add more in TouchBar.js file

export const WorkData = [
  {
    id: "1",
    //images
    homeImage: zeston_app,
    TouchBarImg: zeston_app,
    images: [zeston_app, Zeston_app1],
    filter: ["Mobile", "Web"],
    //details
    clientName: "Zeston App",
    location: "Kerala ",
    category: "Multi-Store Ecommerce Mobile Application",
    footerContent: ` Zeston is a well fledged brand across kerala focussed on wholesale home decor and accessories market.
                     Our challenge was to position their brand and customized products into people's minds and deliver their needs fast.
                     Thus the upgradation to an ecommerce platform was the finest solution. Our teamwork and deication paved the path to its fulfillment, and it happened.
                   `,
    specsContent: [
      "Inventory management",
      "Multi-varient product listing",
      "Seperate Base-product and dealer-product listing",
      "Dealer, shop creation and management",
      "Location based store selection for user",
      "Multi-store order management",
      "Order status allocation and fulfillment options",
      "Revenue analysis and other reports",
      "Notifications for users and dealers",
      "etc.",
    ],
  },

  {
    id: "2",
    //images
    homeImage: Loha_fms_h,
    TouchBarImg: Loha_fms_h,
    images: [Loha_fms1, Loha_fms2, Loha_fms3],
    filter: ["ERP", "Web"],
    //deatils
    clientName: "Loha FMS",
    location: "South India",
    category: "Factory Management System",
    footerContent: ` Factories are hectic with work and workers. Managing them both makes the job tidious. Loha steels is a leading steel doors and windows manufacturing company in kerala.
                     They approached us for making their managing easier and efficient. We provided them with Enterprise Resource Planning Application, inorder to list, maintain, allocate, and manage 
                     all their work segments in a single window. Thus they are more performance oriented and efficient now.
                   `,
    specsContent: [
      "Raw materials listing",
      "Inventory management",
      "Work order and job creation",
      "Dealer order listing and approval with quotation",
      "Multi-level production management",
      "Quality control and quality check options",
      "Staff, labour and other resource management",
      "work order based delivery time estimation and notifications",
      "Factory monitoring dashboard",
      "Revenue analysis and other reports",
      "etc.",
    ],
  },
  {
    id: "3",
    //images
    homeImage: Layamr_h,
    TouchBarImg: Layamr_h,
    images: [Layamr_h, Layamr_web2, Layamr_web1, Layamr_web3, Layamr_web4],
    filter: ["Web"],
    //details
    clientName: "Layamr",
    location: "UAE",
    category: "Ecommerce Web Application",
    footerContent: ` Textiles and Fashion segment needs more customised products nowadays to reach more customers. Layamr is a premium brand for scarfs and underscarfs in Dubai, UAE.
                     They needed a platform to reach their customers digitally and give them products of their choice. We delivered a customized ecommerce platform for layamr with
                     maximum product highlights and video display. 
                   `,
    specsContent: [
      "Multi priced products",
      "Inventory management with stock",
      "Different varient listing with models",
      "video attachment for every product",
      "Customized admin dashboard ",
      "Online payment gateway with all types of card integrations",
      "Smooth checkout and ordering procedures",
      "Order listing and fulfillment status monitoring",
      "Email notifications for users",
      "Guest option for instant checkout",
      "etc.",
    ],
  },
  {
    id: "4",
    //images
    homeImage: Loha_h,
    TouchBarImg: Loha_h,
    images: [Loha_h, Loha_web2, Loha_web1],
    filter: ["ERP", "Web"],
    //deatils
    clientName: "Loha Web",
    location: "South India",

    category: "Brand Profile and Enquiry Acquisition",
    footerContent: ` Brands needed a shoutout to get highlighted in the modern electronic world. This project was a customized website that profiles the company 
                     and its products through media contents. The web app represents the digital version of catelogue and showcases all the specifications related with the items.
                     Enquiry acquisition and management is an importmant segment to improve the customer creation and retention. We have implemented dedicated dashboard for dealing it seamlessly.
                   `,
    specsContent: [
      "Dynamic Products listing",
      "Detailed Featuring",
      "WhatsApp Chat integration",
      "Common and Individual product based Enquiry",
      "Proposal request form",
      "Enquiry Management dashboard",
      "Enquiry reports",
      "etc.",
    ],
  },
  {
    id: "5",
    //images
    homeImage: Zeston_web1,
    TouchBarImg: Zeston_web1,
    images: [
      Zeston_web1,
      Zeston_web_dashboard1,
      Zeston_web_dashboard2,
      Zeston_web_dashboard3,
      Zeston_web_dashboard4,
    ],
    filter: ["Mobile", "Web"],
    //details
    clientName: "Zeston Web",
    location: "Kerala",
    category: "Multi-Store Ecommerce Web Application",
    footerContent: ` Zeston is a well fledged brand across kerala focussed on wholesale home decor and accessories market.
                     Our challenge was to position their brand and customized products into people's minds and deliver their needs fast.
                     Thus the upgradation to an ecommerce platform was the finest solution. Our teamwork and deication paved the path to its fulfillment, and it happened.
                   `,
    specsContent: [
      "Inventory management",
      "Multi-varient product listing",
      "Seperate Base-product and dealer-product listing",
      "Dealer, shop creation and management",
      "Location based store selection for user",
      "Multi-store order management",
      "Order status allocation and fulfillment options",
      "Revenue analysis and other reports",
      "Notifications for users and dealers",
      "etc.",
    ],
  },
  {
    id: "6",
    //images
    homeImage: LohaApp_h,
    TouchBarImg: LohaApp_h,
    images: [LohaApp_h],
    filter: ["ERP", "Web"],
    //deatils
    clientName: "Loha App",
    location: "South India",

    category: "Factory Management System - Mobile App",
    footerContent: ` Factories are hectic with work and workers. Managing them both makes the job tidious. Loha steels is a leading steel doors and windows manufacturing company in kerala.
                     They approached us for making their managing easier and efficient. We provided them with Enterprise Resource Planning Application, inorder to list, maintain, allocate, and manage 
                     all their work segments in a single window. Thus they are more performance oriented and efficient now.
                   `,
    specsContent: [
      "Summary of all processes",
      "Inventory analysis",
      "Work order and job data list",
      "Staff, labour and other resource list",
      "work order monitoring and notifications",
      "Factory monitoring dashboard",
      "Revenue analysis and other reports",
      "etc.",
    ],
  },

  {
    id: "7",
    //images
    homeImage: Ecolums_h,
    TouchBarImg: Ecolums_h,
    images: [Ecolums_h, Ecolums_web1],
    filter: ["Web", "ERP"],
    //details
    clientName: "Ecolums",
    location: "India",

    category: "Investment/Share Management Application",
    footerContent: ` Eco Lums Marketing LLP is an enthusiastic venture to promote and support eco friendly startups and projects. Investment and other cash flow management systems
                    need high end computational methodologies and analysis. We launched an efficient and scalable cash flow integration application with dashboard for both investors to 
                    know about their share values and all the data regarding company they have invested and the profit they received. Management team have the functionality operate the dealings,
                    value creation procedures and all other maintaining of share holders in a single window`,
  },
  {
    id: "8",
    //images
    homeImage: IndtechApp_h,
    TouchBarImg: IndtechApp_h,
    images: [IndtechApp_h],
    filter: ["ERP"],
    //details
    clientName: "Indtech App",
    location: "India",

    category: "Production Management System - Mobile App",
    footerContent: `Factories are hectic with work and workers. Managing them both makes the job tidious. Indtech health care is a leading hospital steel furniture manufacturing company in kerala.
                    They approached us for making their managing easier and efficient. We provided them with Enterprise Resource Planning Application, inorder to list, maintain, allocate, and manage 
                    all their work and manufacturing segments in a single window. Thus they are more performance oriented and efficient now.`,
    // specsContent: []
  },
  {
    id: "Indtech_osperb",
    //images
    homeImage: Indtech_h,
    TouchBarImg: Indtech_h,
    images: [Indtech_h],
    filter: ["ERP"],
    //details
    clientName: "Indtech",
    location: "India",

    category: "Brand Profile",
    footerContent: ` Brands needed a shoutout to get highlighted in the modern electronic world. This project was a customized website that profiles the company 
                     and its products through media contents. The web app represents the digital version of catelogue and showcases all the specifications related with the items.
                   `,
    specsContent: [
      "Dynamic Products listing",
      "Detailed Featuring",
      "WhatsApp Chat integration",
      "etc.",
    ],
  },
  {
    id: "Indtech_Fms_osperb",
    //images
    homeImage: Indtech_fms1,
    TouchBarImg: Indtech_fms1,
    images: [Indtech_fms2, Indtech_fms3, Indtech_fms1],
    filter: ["ERP"],
    //details
    location: "India",

    clientName: "Indtech Fms",
    category: "Production Management System",
    footerContent: `Factories are hectic with work and workers. Managing them both makes the job tidious. Indtech health care is a leading hospital steel furniture manufacturing company in kerala.
                    They approached us for making their managing easier and efficient. We provided them with Enterprise Resource Planning Application, inorder to list, maintain, allocate, and manage 
                    all their work and manufacturing segments in a single window. Thus they are more performance oriented and efficient now.`,
    specsContent: [
      "Raw materials listing",
      "Inventory management",
      "Work order and job creation",
      "Dealer order listing and approval with quotation",
      "Multi-level production management",
      "Quality control and quality check options",
      "Staff, labour and other resource management",
      "Factory monitoring dashboard",
      "Revenue analysis and other reports",
      "etc.",
    ],
  },

  // {
  //   id: "finserv_osperb",
  //   //images
  //   homeImage: "",
  //   TouchBarImg: "",
  //   images: [Indtech1],
  //   filter: ["ERP"],
  //   //details
  //   clientName: " MXT Ideal finserv Web",
  //   category: "carde details",
  //   footerContent: "descriotions",
  // },
  {
    id: "Ipas_osperb",
    //images
    homeImage: Ipasbook_h,
    TouchBarImg: Ipasbook_h,
    images: [Ipas_web1, Ipasbook_h, Ipas_web2],
    filter: ["Web"],
    //details
    clientName: "IPAS",
    location: "GCC and India",

    category: "Acounting Application Profile",
    footerContent: ` Brands needed a shoutout to get highlighted in the modern electronic world. This project was a customized website that profiles the company 
                    and its product 'IPAS BOOK' through media contents. The web app represents the digital version of catelogue and showcases all the specifications related with the app.
                    Dowload options for the product in all devices is also listed in it.
                  `,
  },
  {
    id: "Loyal_osperb",
    //images
    homeImage: loyal_h,
    TouchBarImg: loyal_h,
    images: [Loyal_web1, loyal_h],
    filter: ["Web"],
    //details
    clientName: "Loyal",
    location: "Dubai, UAE",

    category: "Service Profile",
    footerContent: ` Brands needed a shoutout to get highlighted in the modern electronic world. This project was a customized website that profiles the company 
                    and its services through media contents. The web app represents the digital version of catelogue and showcases all the specifications related with the service.
                  `,
  },

  {
    id: "ipasoman_osperb",
    //images
    homeImage: IpasOman_h,
    TouchBarImg: IpasOman_h,
    images: [IpasOman_web1, IpasOman_h],
    filter: ["Web"],
    //details
    clientName: "Ipas Oman",
    location: "Oman",

    category: "Service Profile",
    footerContent: ` Brands needed a shoutout to get highlighted in the modern electronic world. This project was a customized website that profiles the company 
                    and its services through media contents. The web app represents the digital version of catelogue and showcases all the specifications related with the service.
                  `,
  },

  {
    id: "Supriya_osperb",
    //images
    homeImage: Suppriya_h,
    TouchBarImg: Suppriya_h,
    images: [Supriya_web1, Suppriya_h],
    filter: ["Web"],
    //details
    clientName: "Supriya",
    location: "Kerala",

    category: "Product Detailing Website",
    footerContent: `Supriya mattresses is serving the community offering healthy sleep for a healthy life. Our base factory is located at Kondotty in Malappuram.
                    Brands needed a shoutout to get highlighted in the modern electronic world. This project was a customized website that profiles the company 
                    and its products through media contents. The web app represents the digital version of catelogue and showcases all the specifications related with the items.
                   `,
  },
  {
    id: "Deals2Home_osperb",
    //images
    homeImage: Deals2Home_h,
    TouchBarImg: Deals2Home_h,
    images: [Deals2Home_h, Deals2Home,Deals2Home1,Deals2Home2],
    filter: ["Mobile", "Web","ERP"],
    //details
    clientName: "Deals2Home",
    location: "Kerala",

    category: "Dedicated admin dashboard and mobile app",
    footerContent: `Deal 2 Home is a multi-store ecommerce platform consisting of a user web and app, admin app, and dashboard.
     It features a dynamic homepage, category/sub-category listings, product collections, and multiple banner options.
     Users can select their nearest available store, and admins receive order notifications. Push notifications update users on their order status.
                   `,
                   specsContent: [
      "Dynamic homepage for seasonal updates.",
      "	Category and subcategory-based listing with multi-category options.",
      "Product collections listing for special offers.",
      "Multiple banner options.",
      "User location based store  selection and default store at unavailable areas.",
      "Order management and product listing through admin app.",
      "	New order notification to admin app with alarm.",
      "Push notification for order updates.",
      "etc.",
    ],
  },

];
