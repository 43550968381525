import React from "react";
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { Button } from "@material-tailwind/react";

import { Img404 } from "../assets/img";

function NotFound() {
  return (
    <div className="w-full h-full flex items-center justify-center bg-osperb">
      <div className="flex items-center justify-enter">
        <div className="w-60">
          <img
            src={Img404}
            title="front‑end and back‑end development services working closely with our designers. As a UI/UX, web and app development company, we build native mobile apps for iOS and Android, web applications, ERP softwares and other solutions for all your business needs."
            alt="osperb innovetion malappuram provide websites erps applications and so on"
          />
        </div>
        <div className="w-30 flex items-start flex-col justify-center">
          <p className="text-2xl w-70">
            This is not the page you were looking for...
          </p>
          <Link to="/portfolio">
            <Button
              className="custom_btn bg-white text-osperb-blue"
              color="white"
              ripple="light"
              type="submit"
            >
              Explore Our Portfolio
              <FiArrowUpRight size={20} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
