import "../../assets/styles/custom.css";
import Title from "../../components/common/Title";
import ContactCard from "./ContactCard";
import Form from "./Form";
import { BsStar } from "react-icons/bs"
import { GiPencilBrush } from "react-icons/gi"
import { BsBarChartSteps } from "react-icons/bs"

export default function ContactSection() {
  return (
    <section className="pb-20 pt-20 lg:pt-0 relative block bg-gray-100">
      <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
        <Title heading="Why choose us!">
          the best decision towards your vision.
        </Title>

        <div className="flex flex-wrap -mt-12 justify-center">
          <ContactCard icon={<BsStar />} title="We're specialized">
            We're not generalists, we only offer you what we do best: Branded
            assets, UI/UX design, full-stack development, and Business process
            automations.
          </ContactCard>
          <ContactCard icon={<GiPencilBrush />} title="We focus on customization">
            We imprint tailor-made designs with end-functionalities specific for
            your solutions. We accept the uniqueness of each business and enable
            them to stand out in the market.
          </ContactCard>
          <ContactCard icon={<BsBarChartSteps />} title="We go the extra mile.">
            {" "}
            We empathise your problems as our challenges and research on it to
            develop feasible solutions that deliver your expectations.
          </ContactCard>
        </div>
        <div id="contact"></div>
        <Form />
      </div>
    </section>
  );
}
