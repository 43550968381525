import { Icon, Image } from "@material-tailwind/react";
import { osperb } from "../assets/img";
import { MdLocationOn } from "react-icons/md";

export default function DefaultFooter() {
  return (
    <>
      <footer className="relative bg-gray-100 pt-8 pb-6">
        <div className="container max-w-7xl mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left pt-6">
            <div className="w-full lg:w-6/12 ">
              <Image
                className="max-w-sm w-3/12 footer_logo"
                src={osperb}
                alt="website development company in india"
                title="website development company in india"
                height="60"
                width="150"
                loading="lazy"
              />

              <div className="-mt-4">
                <p
                  color="blueGray"
                  className="text-blue-gray-700 text-sm font-light leading-relaxed mt-6 mb-4"
                >
                  Delivering technology solutions for all
                </p>
                <p
                  color="blueGray"
                  className="text-blue-gray-700 text-sm font-light leading-relaxed"
                >
                  {/* <MdLocationOn
                    style={{ color: "black" }}
                    className="mr-1"
                    size={"1.2rem"}
                  /> */}
                  National Highway 966, Malappuram Kerala
                </p>
              </div>
              <div className="flex gap-2 mt-6 md:justify-start md:mb-0 mb-8 justify-center footer_icon">
                {/* <a
                  href="https://www.facebook.com/CreativeTim"
                  className="grid place-items-center bg-white text-blue-600 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    family="font-awesome"
                    name="fab fa-facebook-square"
                    style={{ color: "black" }}
                  />
                </a> */}
                {/* <a
                  href="https://www.twitter.com/creativetim"
                  className="grid place-items-center bg-white text-blue-400 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    family="font-awesome"
                    name="fab fa-twitter"
                    style={{ color: "black" }}
                  />
                </a> */}
                <a
                  href="https://www.instagram.com/osperb_innovations/"
                  className="grid place-items-center bg-white text-indigo-500 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    family="font-awesome"
                    name="fab fa-instagram"
                    style={{ color: "black" }}
                  />
                </a>
                <a
                  href="mailto:hi@osperb.com"
                  className="grid place-items-center bg-white text-pink-400 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    family="font-awesome"
                    name="fas fa-envelope"
                    style={{ color: "black" }}
                  />
                </a>
                {/* <a
                  href="https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w"
                  className="grid place-items-center bg-white text-red-600 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    family="font-awesome"
                    name="fab fa-youtube"
                    style={{ color: "black" }}
                  />
                </a> */}
                <a
                  href="https://github.com/osperb"
                  className="grid place-items-center bg-white text-gray-900 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon
                    family="font-awesome"
                    name="fab fa-github"
                    style={{ color: "black" }}
                  />
                </a>
                <a
                  href="https://g.page/osperb?share"
                  className="grid place-items-center bg-white text-blue-600 shadow-none font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MdLocationOn style={{ color: "black" }} />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-6/12 ">
              <div className="flex flex-wrap items-top">
                <div className="w-full lg:w-4/12 px-4 ml-auto md:mb-0 mb-8">
                  <ul className="list-unstyled">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>

                {/* <div className="w-full lg:w-4/12 sm:w-6/12 px-4">
                  <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                    Other Resources
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/services/website"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Website devt.
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service/mobileapp"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Mobile App devt.
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service/softwear"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Software devt.
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/ui-ux"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        UI-UX
                      </Link>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="w-full lg:w-4/12 sm:w-6/12 px-4">
                  <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                    <Link to="/technologies">Technologies</Link>
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        to="/technologies/node"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Node js
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/technologies/python"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Python / Django
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/technologies/react"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        React js
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        className="text-gray-700 hover:text-gray-900 block pb-2 text-sm"
                      >
                        Flutter
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-700 font-medium py-1">
                Copyright © {new Date().getFullYear()}{" "}
                <a
                  href="/"
                  className="text-gray-700 hover:text-gray-900 transition-all"
                  title="website development firm"
                >
                  Osperb
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
